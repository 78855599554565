// ./
import Config from '@/config'

// Classes
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'

// Constants
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'
import MixinFetch     from '@/Mixins/MixinFetch'

// Component Extend
const View01 = VueMixins(MixinBase, MixinComponent, MixinFetch).extend({
	name: VueRouter.Global.View01.NAME,

	data: function () {
		return {
			states: {
				_id: undefined,
				action: 'SEND_MAIL',
				email: '',
				code: '',
				isFetching: false,
				password: ''
			}
		}
	},

	created: async function() {
		// Ingresó por medio del Correo enviado.
		const { action, email } = this.$route.query
		if (action === 'ENTER_CODE') {
			this.setStates<View01Ref['states']>({ action, email: email as string })
		}
	},

	computed: {
		_isActionEnterCode: function() {
			return this.states.action === 'ENTER_CODE'
		},

		_isActionMailSent: function() {
			return this.states.action === 'MAIL_SENT'
		},

		_isActionSendMail: function() {
			return this.states.action === 'SEND_MAIL'
		},

		_isActionSendPassword: function() {
			return this.states.action === 'SEND_NEW_PASSWORD'
		},

		_isValidEmail: function() {
			return PrimitiveTools.Strings.isValidEmail(this.states.email)
		}
	},

	methods: {
		_getBasePath: function() {
			const { protocol, hostname } = Config.axios
			return `${ protocol }://${ hostname }`
		},

		_getDescription: function() {
			switch (this.states.action) {
				case 'ENTER_CODE'       : return `Hemos enviado una codigo de verificacion a ${ this.states.email } Ingresando la codigo de verificacion podrá restablecer su contraseña.`
				case 'MAIL_SENT'        : return 'En caso de ser un E-mail autorizado, se enviará una clave de acceso para que pueda restablecer su contraseña.'
				case 'PROCESS_COMPLETED': return 'Ahora puede regresar al Login e Iniciar Sesión con su nueva Contraseña.'
				case 'SEND_MAIL'       : return 'Ingrese el correo electrónico asociado a su cuenta, esto le permitirá establecer una nueva contraseña.'
				case 'SEND_NEW_PASSWORD': return 'Ingrese una nueva Contraseña, la cual será actualizada en la cuenta asociada al Correo que fue especificado anteriormente.'
			}
		},

		_getPlaceHolder: function() {
			switch (this.states.action) {
				case 'ENTER_CODE'       : return 'Inngrese el Código de Verificación'
				case 'SEND_MAIL'       : return 'Ingrese su Correo Electronico'
				case 'SEND_NEW_PASSWORD': return 'Ingresar Contraseña'
			}
		},

		_getSubmitButtonText: function() {
			// Mensaje cuando una Petición se encuentra en curso.
			if (this.states.isFetching) return 'Por favor, espere un momento.'
			switch (this.states.action) {
				case 'ENTER_CODE'       : return 'Verificar Código'
				case 'SEND_MAIL'       : return 'Siguiente'
				case 'SEND_NEW_PASSWORD': return 'Actualizar Contraseña'
			}
		},

		_getTitle: function() {
			switch (this.states.action) {
				case 'ENTER_CODE'       : return 'Código de Verificacción'
				case 'MAIL_SENT'        : return 'Mensaje Enviado!'
				case 'PROCESS_COMPLETED': return 'Proceso Finalizado'
				case 'SEND_MAIL'       : return 'Restablecer Contraseña'
				case 'SEND_NEW_PASSWORD': return 'Ingresar Nueva Contraseña'
			}
		},

		_onSendCode: async function() {
			const { code, email } = this.states
			if (code && code.trim() !== '') {
				// Objeto con las Propiedades requeridas por la Petición.
				const body = { email, code }

				// Realizar la Petición al servidor.
				this.setStates<View01Ref['states']>({ isFetching: true })
				const response = await this.doFetch({ action: Server.Fetching.Method.POST, path: Server.Routes.Emails.VerifyCode, body })

				// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
				if (response.status === Server.Response.StatusCodes.SUCCESS && response.data.body) {
					this.showToast('Verificación Exitosa', 'A continuación, especifique una nueva Contraseña', 'success')
					this.setStates<View01Ref['states']>({ _id: response.data.body._id, action: 'SEND_NEW_PASSWORD', code: '', email: '', isFetching: false })
					return
				}

				// Especificar que el Código de Verificación sea valido.
				this._showToast('INVALID_CODE')
				this.setStates<View01Ref['states']>({ isFetching: false })
				return
			}

			// Mostrar Mensaje Toast.
			this._showToast('EMPTY_CODE')
		},

		_onSendEmail: async function() {
			const { email } = this.states
			if (email && email.trim() !== '') {
				if (this._isValidEmail) {
					// Objeto con las Propiedades requeridas por la Petición.
					const body = {
						email,
						link: `${ this._getBasePath() }/#${ VueRouter.Global.View01.PATH }?action=ENTER_CODE&email=${ email }`
					}

					// Realizar la Petición al servidor.
					this.setStates<View01Ref['states']>({ isFetching: true })
					const response = await this.doFetch({ action: Server.Fetching.Method.POST, path: Server.Routes.Emails.RequestPassword, body })

					// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
					if (response.status === Server.Response.StatusCodes.SUCCESS) {
						this.showToast('Correo Enviado', 'Se ha enviado un correo con las instrucciones para reiniciar tu contraseña!', 'success')
						this.setStates<View01Ref['states']>({ action: 'MAIL_SENT', email: '', isFetching: false })
						return
					}
				}

				// Especificar que el formato del Correo no es valido.
				this._showToast('INVALID_EMAIL_FORMAT')
				this.setStates<View01Ref['states']>({ isFetching: false })
				return
			}

			// Mostrar Mensaje Toast.
			this._showToast('SEND_MAIL')
		},

		_onSendNewPassword: async function() {
			const { _id, email, password } = this.states
			if ((email && email.trim() !== '') && (password && password.trim() !== '') && (email === password)) {
				// Objeto con las Propiedades requeridas por la Petición.
				const body = { _id, password, email: this.$route.query.email }

				// Realizar la Petición al servidor.
				this.setStates<View01Ref['states']>({ isFetching: true })
				const response = await this.doFetch({ action: Server.Fetching.Method.PATCH, path: Server.Routes.Users.UpdateUserPassword, body })

				// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
				if (response.status === Server.Response.StatusCodes.SUCCESS) {
					this.showToast('Contraseña Restablecida!', 'Su Contraseña fue actualizada correctamente!', 'success')
					this.setStates<View01Ref['states']>({ action: 'PROCESS_COMPLETED', email: '', isFetching: false })
					return
				}
			}

			// Mostrar Mensaje Toast.
			this._showToast('VERIFY_PASSWORD')
		},

		_showToast: function (key: string) {
			switch (key) {
				case 'SEND_MAIL':
					return this.showToast('Campo Requerido', 'Debe rellenar el campo con un Correo Electronico que sea valido!', 'warning')
				case 'EMPTY_CODE':
					return this.showToast('Campo Requerido', 'Debe especificar un Código de Verificación', 'warning')
				case 'INVALID_EMAIL_FORMAT':
					return this.showToast('Correo Electronico No Valido', 'Debe ingresar un Correo con un formato valido!', 'warning')
				case 'INVALID_CODE':
					return this.showToast('Código No Valido', 'Compruebe que el Código de Verificación es el mismo que fue enviado a su correo, además tenga presente que el Código es valido unicamente por 30 minutos!', 'warning')
				case 'VERIFY_PASSWORD':
					return this.showToast('Verifique Campos', 'Verifique que ambos campos hayan sido rellenados y que ambas Contraseñas sean exactamente iguales!', 'warning')
			}
		},

		onBackToLogin: function() {
			this.$router.push({ name: VueRouter.Global.View00.NAME })
		},

		onEmailInput() {
			this.states.email = this.states.email.toLowerCase();
		  },

		onServerCaughtFetchException: function (path: string, resolution: Server.Fetching.Resolutions, status: number, error: any) {
			if (path === Server.Routes.Emails.VerifyCode) {
				// Especificar que el Código de Verificación sea valido.
				this._showToast('INVALID_CODE')
				this.setStates<View01Ref['states']>({ isFetching: false })
			}
		},

		onSubmitClick: function() {
			switch (this.states.action) {
				case 'ENTER_CODE'       : return this._onSendCode()
				case 'SEND_MAIL'        : return this._onSendEmail()
				case 'SEND_NEW_PASSWORD': return this._onSendNewPassword()
			}
		}
	}
})

// Component Export
export default View01
export type View01Ref = InstanceType<typeof View01>